<template>
  <div>
    <form>
      <div class="row">
        <div
          class="form-group col-sm-6"
          :class="{ 'has-error': errors.has('categoryId') }"
        >
          <label>
            {{ $t("categories") }}
          </label>
          <v-select
            name="categoryId"
            multiple
            v-model="selectedCategories"
            v-validate="{
              required: selectedProducts.length < 1
            }"
            label="name"
            @input="changeListData()"
            :options="categoryList"
            :reduce="category => category.id"
          ></v-select>
          <small v-show="errors.has('categoryId')" class="text-danger">
            {{ $t("field_required") }}
          </small>
        </div>
        <div
          class="form-group col-sm-6"
          :class="{ 'has-error': errors.has('productId') }"
        >
          <label>
            {{ $t("products") }}
          </label>
          <v-select
            name="productId"
            multiple
            v-model="selectedProducts"
            v-validate="{
              required: selectedCategories.length < 1
            }"
            label="name"
            @input="changeListData()"
            :options="productList"
            :reduce="product => product.id"
          ></v-select>
          <small v-show="errors.has('productId')" class="text-danger">
            {{ $t("field_required") }}
          </small>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-4 col-sm-offset-2">
          <a class="btn btn-primary btn-sm text-white" @click="handleSubmit()">
            Pobierz
          </a>
        </div>
      </div>
    </form>
    <div v-if="runGetProduct">
      <hr />
      <AddTagsForm
        :selectedCategories="selectedCategories"
        :selectedProducts="selectedProducts"
      >
      </AddTagsForm>
    </div>
  </div>
</template>

<script>
import AddTagsForm from "@/components/Products/Tags/AddTagsForm.vue";

export default {
  name: "ProductTagsSelectForm",
  data() {
    return {
      categoryList: [],
      productList: [],
      selectedCategories: [],
      selectedProducts: [],
      runGetProduct: false
    };
  },
  components: {
    AddTagsForm
  },
  props: {},
  async created() {
    this.$emit("loading", false);
    await this.getCategoryList();
    await this.getProductList();
  },
  methods: {
    getCategoryList() {
      this.$emit("loading", true);
      return this.$http
        .get(
          `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/lists/categories`
        )
        .then(resp => {
          this.categoryList = resp.data.data;
          this.$emit("loading", false);
          return this.categoryList;
        })
        .catch(() => {
          this.$emit("loading", false);
          return false;
        });
    },
    getProductList() {
      this.$emit("loading", true);
      return this.$http
        .get(
          `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/lists/products`
        )
        .then(resp => {
          this.productList = resp.data.data;
          this.$emit("loading", false);
          return this.productList;
        })
        .catch(() => {
          this.$emit("loading", false);
          return false;
        });
    },
    restartForm() {
      this.runGetProduct = false;
      this.selectedCategories = [];
      this.selectedProducts = [];
      this.$validator.reset();
    },
    changeListData() {
      if (this.runGetProduct == true) {
        this.runGetProduct = false;
      }
    },
    handleSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.runGetProduct = true;
        }
      });
    }
  }
};
</script>
