<template>
  <div>
    <form>
      <DataTables
        ref="dataTables"
        @list="loadData()"
        :url="this.url"
        :columns_list="columns_list"
        :theadRow="false"
      />
      <div class="row">
        <div
          class="form-group col-12"
          :class="{ 'has-error': errors.has('newTags') }"
        >
          <label> {{ $t("new_tags") }} </label>
          <textarea
            class="form-control"
            name="newTags"
            v-model="newTags"
            v-validate="'required'"
            rows="10"
          >
          </textarea>
          <small v-show="errors.has('newTags')" class="text-danger">
            {{ $t("field_required") }}
          </small>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-right">
          <a
            class="btn text-white btn-w-m btn-primary"
            @click="handleSubmit()"
            type="submit"
            >{{ $t("save") }}</a
          >
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import DataTables from "@/components/base/DataTables.vue";

export default {
  name: "AddTagsForm",
  data() {
    return {
      newTags: "",
      columns_list: [
        {
          key: "selected",
          name: "Wybór",
          type: "number",
          sortable: false,
          filterable: false,
          touch: true,
          filter: "btn",
          class: "text-center",
          dont_refresh: true,
          function: (row, data) => this.checkedProduct(row, data),
          btn: (a, row) =>
            `<input type="checkbox" id="product-${a}" name="product-${a}" ${
              row.selected ? "checked" : ""
            }>`
        },
        {
          key: "id",
          name: "Id",
          type: "number",
          sortable: false,
          filterable: false
        },
        {
          key: "name",
          name: "Nazwa",
          type: "text",
          sortable: false,
          filterable: false
        },
        {
          key: "plum",
          name: "Plum",
          type: "text",
          sortable: false,
          filterable: false
        }
      ]
    };
  },
  props: {
    selectedCategories: {
      type: Array,
      required: true
    },
    selectedProducts: {
      type: Array,
      required: true
    }
  },
  components: {
    DataTables
  },
  computed: {
    url() {
      this.$parent.$emit("loading", true);
      let url = `/${this.$route.params.shop_name}/${this.$route.params.shop_lang}/products/tags`;
      if (this.selectedCategories.length > 0) {
        url = `${url}?categories=${this.selectedCategories}`;
      }
      if (this.selectedProducts.length > 0) {
        url +=
          this.selectedCategories.length > 0
            ? `&products=${this.selectedProducts}`
            : `?products=${this.selectedProducts}`;
      }
      return url;
    }
  },
  methods: {
    checkedProduct(row, data) {
      data.selected = data.selected == 1 ? 0 : 1;
    },
    loadData() {
      this.$parent.$emit("loading", false);
    },

    handleSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$parent.$emit("loading", true);
          let dataToSave = {
            products: this.$refs.dataTables.table_list.filter(element => {
              if (element.selected == 1) {
                return element;
              }
            }),
            tags: this.newTags
          };
          this.$http
            .put(
              `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/products/tags`,
              dataToSave
            )
            .then(() => {
              this.$parent.$emit("loading", false);
              this.$parent.restartForm();
              this.$toastr.success(this.$t("added_tags"));
            })
            .catch(() => {
              this.$parent.$emit("loading", false);
              this.$toastr.error(this.$t("error_add_tags"));
            });
        }
      });
    }
  }
};
</script>
