<template>
  <div class="row">
    <div class="col-12">
      <ibox :title="$t('tags')">
        <template slot="content" slot-scope="{ setLoading }">
          <SelectForm @loading="setLoading"></SelectForm>
        </template>
      </ibox>
    </div>
  </div>
</template>

<script>
import Ibox from "@/components/base/Ibox.vue";
import SelectForm from "@/components/Products/Tags/SelectForm.vue";

export default {
  name: "PaymentMethodsStatistics",
  components: {
    Ibox,
    SelectForm
  },
  data() {
    return {};
  }
};
</script>

<style>
.dataTables_info {
  display: none;
}
.dataTables_length {
  display: none;
}
.pagination {
  display: none;
}
</style>
